import { flexCenter, flexSpace, px } from '@/layout/styles/classes';
import { textGradient } from '@/layout/styles/Typography';
import { NavBtnsDesktop } from '@/ServicesSlider';
import { Tag } from '@/Tag';
import styled from '@emotion/styled';
import loadable from '@loadable/component';
import useIntersectionObserver from '@s/hooks/useObserver';
import { useRef } from 'react';

const StyledServices = styled.section`
    padding-top: 16px;
    padding-bottom: 64px;

    .swiper-slide {
        width: max-content;

        :first-of-type {
            margin-left: 16px;
        }

        :last-of-type {
            margin-right: 16px;
        }
    }

    @media (min-width: 1024px) {
        padding-top: 64px;
        padding-bottom: 96px;

        .swiper-slide {
            :first-of-type {
                margin-left: 32px;
            }

            :last-of-type {
                margin-right: 32px;
            }
        }
    }

    @media (min-width: 1366px) {
        margin-left: ${({ theme }) => theme.spacing.xl};

        .swiper-slide {
            :first-of-type {
                margin-left: 0;
            }
        }
    }
`;

const TopBox = styled.div`
    ${px};
    margin-bottom: 32px;

    h2 {
        ${textGradient};
        margin: 16px 0 0;
        font-weight: 700;
    }

    @media (min-width: 1024px) {
        ${flexSpace};
        margin-bottom: 64px;
    }

    @media (min-width: 1366px) {
        padding-left: 64px;
        width: 83.33vw;
    }

    @media (min-width: 1600px) {
        max-width: 1550px;
        margin-left: auto;
        margin-right: auto;
        padding-right: 0;
    }
`;

const Fallback = styled.div`
    ${flexCenter};
    height: 474px;

    @media (min-width: 1024px) {
        height: 450px;
    }
`;

const SliderComponent = loadable(() => import('../ServicesSlider'), {
    resolveComponent: components => components.ServicesSlider,
    fallback: <Fallback>Loading...</Fallback>,
});

export const Services = () => {
    const ref = useRef<HTMLDivElement | null>(null);
    const entry = useIntersectionObserver(ref, {
        freezeOnceVisible: true,
        rootMargin: '300px 0px 0px',
    });
    const isVisible = !!entry?.isIntersecting;

    return (
        <StyledServices ref={ref}>
            <TopBox>
                <div>
                    <Tag text="dental services" />
                    <h2>SERVICES</h2>
                </div>
                <NavBtnsDesktop />
            </TopBox>
            {isVisible ? <SliderComponent /> : <Fallback>Loading...</Fallback>}
        </StyledServices>
    );
};
