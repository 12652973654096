import { ArrowBtn } from '@/buttons';
import CLink from '@/CLink';
import { getSubNavLink } from '@/layout/NavLinks';
import { flexCenter, px } from '@/layout/styles/classes';
import { caption, h2, h3, largeText, textGradient } from '@/layout/styles/Typography';
import { Tag } from '@/Tag';
import accept from '@a/images/homepage/different/accept.svg';
import affordable from '@a/images/homepage/different/affordable.svg';
import hours from '@a/images/homepage/different/hours.svg';
import insurance from '@a/images/homepage/different/insurance.svg';
import technology from '@a/images/homepage/different/technology.svg';
import mobTexture from '@a/images/homepage/different/texture.svg';
import desktopTexture from '@a/images/texture-desktop.svg';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const StyledDifferent = styled.section`
    ${px};
    background-image: url(${mobTexture});
    background-repeat: no-repeat;
    background-position: top center;
    padding-top: 170px;
    padding-bottom: 16px;
    margin-top: -107px;

    @media (min-width: 540px) {
        background-size: 100% auto;
    }

    @media (min-width: 768px) {
        background-image: url(${desktopTexture});
    }

    @media (min-width: 1024px) {
        margin-top: 0;
        padding-top: 112px;
        padding-bottom: 64px;
    }
`;

const TextBox = styled.div`
    text-align: center;
    margin-bottom: 32px;

    h2 {
        ${h3};
        ${textGradient};
        margin: 0;
    }

    p {
        ${largeText};
        margin: 0 0 32px;
    }

    a {
        width: max-content;
        display: block;
        margin: 0 auto;
    }

    @media (min-width: 1024px) {
        ${flexCenter};
        text-align: left;
        margin-bottom: 64px;

        > div {
            max-width: 780px;
            margin-right: ${({ theme }) => theme.spacing.xxl};

            > p {
                margin: 0;
            }
        }

        > a {
            flex-shrink: 0;
            margin: 0;
        }
    }
`;

const Middle = styled.div`
    h3 {
        ${h2};
        ${textGradient};
        margin: 16px 0 32px;
    }

    .gatsby-image-wrapper {
        filter: drop-shadow(${({ theme }) => theme.colors.shadow});
        border-radius: 16px;
    }

    @media (min-width: 1024px) {
        ${flexCenter};

        h3 {
            margin: 32px 0;
            font-weight: 700;
            max-width: 402px;
        }
    }
`;

const Wrapper = styled.div`
    position: relative;
    margin-top: 32px;
    width: fit-content;

    > h4 {
        ${flexCenter};
        flex-direction: column;
        position: absolute;
        bottom: -64px;
        left: 50%;
        transform: translateX(-50%);
        width: 128px;
        height: 128px;
        border-radius: 50%;
        filter: drop-shadow(${({ theme }) => theme.colors.shadow});
        background-image: ${({ theme }) => theme.colors.gradient};
        margin: 0;

        > span {
            color: ${({ theme }) => theme.colors.white};

            :first-of-type {
                ${largeText};
                font-weight: 400;
            }

            :last-of-type {
                ${h2};
                margin: 2px 0 0;
            }
        }
    }

    > .gatsby-image-wrapper {
        filter: drop-shadow(${({ theme }) => theme.colors.shadow});
        border-radius: 16px;
    }

    @media (min-width: 1024px) {
        align-self: flex-start;
        margin-top: 0;
        margin-left: ${({ theme }) => theme.spacing.md};
    }

    @media (min-width: 1280px) {
        > h4 {
            width: 160px;
            height: 160px;
        }
    }
`;

const Features = styled.div`
    display: flex;
    overflow-x: auto;
    margin: 96px -16px 0;

    @media (min-width: 1024px) {
        margin: 64px 0 0;
        display: grid;
        grid-template-columns: repeat(3, 218px);
        justify-content: center;
        gap: 45px ${({ theme }) => theme.spacing.lg};
    }
`;

const Card = styled.div`
    width: 218px;
    height: 218px;
    padding: 8px;
    flex-shrink: 0;
    margin-right: 32px;
    border-radius: 32px;
    border: 1px solid transparent;
    position: relative;
    background-clip: padding-box;
    background-color: ${({ theme }) => theme.colors.white};

    ::before,
    ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        margin: -1px;
        border-radius: inherit;
        transition: opacity 0.3s ease-in-out;
    }

    :before {
        background-image: linear-gradient(
            180deg,
            #035d7d 0%,
            #039ec2 48.47%,
            rgba(3, 158, 194, 0) 94.95%
        );
    }

    ::after {
        background-image: linear-gradient(133.35deg, #035d7d 2.71%, #039ec2 83.79%);
        opacity: 0;
    }

    :hover {
        ::before {
            opacity: 0;
        }

        ::after {
            opacity: 1;
        }
    }

    :first-of-type {
        margin-left: 16px;
    }

    :last-of-type {
        margin-right: 16px;
    }

    > h5 {
        ${h3};
        text-transform: capitalize;
    }

    > p {
        ${caption};
    }

    > h5,
    > p {
        color: ${({ theme }) => theme.colors.dark};
        margin: 0;
    }

    @media (min-width: 1024px) {
        margin: 0 !important;

        :nth-last-of-type(2),
        :last-of-type {
            justify-self: center;
            grid-row: 2;
        }

        :nth-last-of-type(2) {
            grid-column: 1 / span 2;
        }

        :last-of-type {
            grid-column: 2 / span 2;
        }
    }
`;

const features = [
    {
        img: accept,
        title: 'we accept',
        text: 'We accept dentical / medi-Cal',
    },
    {
        img: insurance,
        title: 'Insurances',
        text: 'We accept most PPO Insurances',
    },
    {
        img: hours,
        title: 'Flexible Hours',
        text: 'We accept most PPO Insurances',
    },
    {
        img: affordable,
        title: 'Affordable',
        text: 'Affordable orthodontics (braces) by board certified orthodontist',
    },
    {
        img: technology,
        title: 'Technology',
        text: 'Latest 3D Technology for the most non-invasive solutions',
    },
];

export const Different = () => {
    return (
        <StyledDifferent>
            <TextBox>
                <div>
                    <h2>What makes us Different?</h2>
                    <p>
                        From 3D technology, to the affordability from all corners. We are always at
                        the cutting edge of dentistry. Making it easy in every way imaginable.
                    </p>
                </div>
                <ArrowBtn as={CLink} to={getSubNavLink('contact us')}>
                    Get in touch
                </ArrowBtn>
            </TextBox>
            <Middle>
                <div>
                    <Tag text="technology" />
                    <h3>Always at the Cutting Edge</h3>
                    <StaticImage
                        src="../../../assets/images/homepage/different/always-at-the-cutting-edge-1.jpg"
                        alt="kavo op 3d cutting edge xray machine"
                        width={475}
                        height={440}
                    />
                </div>
                <Wrapper>
                    <StaticImage
                        src="../../../assets/images/homepage/different/always-at-the-cutting-edge-2.jpg"
                        alt="dr luay diagnosing patient"
                        width={475}
                        height={490}
                    />
                    <h4>
                        <span>since</span>
                        <span>2001</span>{' '}
                    </h4>
                </Wrapper>
            </Middle>
            <Features>
                {features.map((feature, i) => (
                    <Card key={i}>
                        <img src={feature.img} alt={feature.title} width={64} height={64} />
                        <h5>{feature.title}</h5>
                        <p>{feature.text}</p>
                    </Card>
                ))}
            </Features>
        </StyledDifferent>
    );
};
