import { flexCenter } from '@/layout/styles/classes';
import { caption } from '@/layout/styles/Typography';
import styled from '@emotion/styled';

export const ImageArticle = styled.article<{ right?: boolean }>`
    .gatsby-image-wrapper {
        width: 100%;
        height: 300px;
        max-width: 300px;
        border-radius: 50%;
        filter: drop-shadow(${({ theme }) => theme.colors.shadow});
        margin-bottom: 32px;
    }

    > div:last-of-type {
        > h2 {
            color: ${({ theme }) => theme.colors.dark};
            margin: 0 0 32px;
            text-transform: capitalize;
        }

        > p {
            ${caption};
            margin: 0;
            max-width: 720px;
        }

        > a {
            margin-top: 32px;
        }
    }

    @media (min-width: 768px) {
        .gatsby-image-wrapper {
            max-width: 500px;
            height: 500px;
        }
    }

    @media (min-width: 1024px) {
        ${flexCenter};

        .gatsby-image-wrapper {
            margin-bottom: 0;
            margin-right: ${({ theme, right }) => (right ? 0 : theme.spacing.xxl)};
            margin-left: ${({ theme, right }) => (right ? theme.spacing.xxl : 0)};
            order: ${({ right }) => (right ? 1 : 'unset')};
        }

        > div:last-of-type {
            > h2 {
                font-weight: 700;
            }

            > p {
                max-width: 450px;
            }
        }
    }
`;
