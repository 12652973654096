import { ArrowBtn } from '@/buttons';
import CLink from '@/CLink';
import { getSubNavLink } from '@/layout/NavLinks';
import { flexSpace, flexStart } from '@/layout/styles/classes';
import { textGradient } from '@/layout/styles/Typography';
import Slider from '@/Slider';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { Fragment } from 'react';
import { Navigation } from 'swiper';
// eslint-disable-next-line
import 'swiper/css/navigation';
// eslint-disable-next-line
import { SwiperSlide } from 'swiper/react';

const StyledFeature = styled(CLink)<{ charcount: number }>`
    display: grid;
    border-radius: 8px;
    overflow: hidden;
    width: 300px;

    > .gatsby-image-wrapper {
        grid-area: 1/1;
    }

    > div:last-of-type {
        ${flexSpace};
        grid-area: 1/1;
        align-self: end;
        background: rgba(244, 251, 255, 0.62);
        backdrop-filter: blur(16px);
        padding: 6px 16px;
        transition: padding 0.3s ease-in-out;

        > h3 {
            ${textGradient};
            margin: 0;
            text-transform: capitalize;
        }

        > button {
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }
    }

    :hover,
    :focus-visible {
        > div:last-of-type {
            padding: ${({ charcount }) => (charcount > 14 ? '34px 16px' : '53px 16px')};

            > button {
                opacity: 1;
            }
        }
    }

    @media (min-width: 1024px) {
        width: 350px;

        > div:last-of-type {
            padding: 16px;
        }

        :hover,
        :focus-visible {
            > div:last-of-type {
                padding: ${({ charcount }) => (charcount > 14 ? '47px 16px' : '66px 16px')};
            }
        }
    }
`;

const Btn = styled(ArrowBtn)`
    padding: 0;
    width: 56px;
    height: 56px;
    flex-shrink: 0;

    ::after {
        margin-left: 0;
        transform: scale(1);
        line-height: 14px;
    }

    &.home-services-slider-disabled {
        box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.textBlue};
        pointer-events: none;

        ::before {
            opacity: 0;
        }

        ::after {
            filter: invert(40%) sepia(21%) saturate(467%) hue-rotate(165deg) brightness(101%)
                contrast(95%);
        }
    }

    &.swiper-button-lock {
        display: none;
    }
`;

const NavBtns = styled.div<{ desktop?: boolean }>`
    ${flexStart};
    margin-top: 32px;
    padding-left: 16px;
    display: ${({ desktop }) => (desktop ? 'none' : 'flex')};

    > button {
        :first-of-type {
            margin-right: 32px;
            ::after {
                transform: scaleX(-1);
            }
        }
    }

    @media (min-width: 1024px) {
        padding-left: 0;
        display: ${({ desktop }) => (desktop ? 'flex' : 'none')};
        margin-top: 0;
    }
`;

export const NavBtnsDesktop = () => (
    <NavBtns desktop>
        <Btn className="home-services-slider-prev" aria-label="go back to previous feature slide" />
        <Btn className="home-services-slider-next" aria-label="go to next feature slide" />
    </NavBtns>
);

export const ServicesSlider = () => {
    type DataTypes = {
        allFile: {
            nodes: {
                childImageSharp: ImageDataLike;
                name: string;
            }[];
        };
    };

    const data: DataTypes = useStaticQuery(graphql`
        query servicesSlider {
            allFile(
                filter: { relativeDirectory: { eq: "homepage/services" } }
                sort: { order: ASC, fields: name }
            ) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(width: 350, height: 450)
                    }
                    name
                }
            }
        }
    `);

    const services = data.allFile.nodes.map(node => ({
        img: getImage(node.childImageSharp) as IGatsbyImageData,
        name: node.name.replace(/-/g, ' '),
    }));

    const sliderSettings = {
        navigation: {
            nextEl: '.home-services-slider-next',
            prevEl: '.home-services-slider-prev',
            disabledClass: 'home-services-slider-disabled',
        },
        modules: [Navigation],
        spaceBetween: 16,
        slidesPerView: 'auto' as const,
        breakpoints: {
            1024: {
                spaceBetween: 40,
            },
        },
    };
    return (
        <Fragment>
            <Slider sliderSettings={sliderSettings}>
                {services.map((service, i) => (
                    <SwiperSlide key={i}>
                        <StyledFeature
                            to={getSubNavLink(service.name)}
                            charcount={service.name.length}
                        >
                            <GatsbyImage image={service.img} alt={service.name} />
                            <div>
                                <h3 id={service.name}>{service.name}</h3>
                                <Btn aria-label={service.name} />
                            </div>
                        </StyledFeature>
                    </SwiperSlide>
                ))}
            </Slider>

            <NavBtns>
                <Btn
                    className="home-services-slider-prev"
                    aria-label="go back to previous feature slide"
                />
                <Btn className="home-services-slider-next" aria-label="go to next feature slide" />
            </NavBtns>
        </Fragment>
    );
};
