import { AboutDr } from '@/homepage/AboutDr';
import { Different } from '@/homepage/Different';
import { Services } from '@/homepage/Services';
import { Seo } from '@/layout/Seo';
import { Fragment } from 'react';

const Home: Page = () => (
    <Fragment>
        <Seo />
        <Different />
        <Services />
        <AboutDr />
    </Fragment>
);

export default Home;
