import { ArrowBtn, PrimaryBtn } from '@/buttons';
import CLink from '@/CLink';
import { ImageArticle } from '@/ImageArticle';
import { getSubNavLink } from '@/layout/NavLinks';
import { flexSpace, px } from '@/layout/styles/classes';
import { textGradient } from '@/layout/styles/Typography';
import { Tag } from '@/Tag';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const StyledDr = styled.section`
    ${px};
    padding-top: 32px;
    padding-bottom: 32px;
    background-color: ${({ theme }) => theme.colors.lightBlue};

    @media (min-width: 1024px) {
        padding-top: 64px;
        padding-bottom: 64px;
    }
`;

const Box = styled.div`
    margin: 0 0 32px;

    h2 {
        ${textGradient};
        margin: 32px 0 16px;
        font-weight: 700;
        min-width: 237px;
        width: 63.2vw;
        max-width: 400px;
    }

    @media (min-width: 1024px) {
        ${flexSpace};
        max-width: 74.86vw;
    }

    @media (min-width: 1280px) {
        margin: 0 auto 32px;
        max-width: calc(500px + ${({ theme }) => theme.spacing.xxl} + 540px);

        > a {
            margin-right: 5.97vw;
        }
    }
`;

export const AboutDr = () => {
    return (
        <StyledDr>
            <Box>
                <div>
                    <Tag text="our doctor" />
                    <h2>Leading the way in San Diego</h2>
                </div>
                <PrimaryBtn as={CLink} to={getSubNavLink('our doctors')}>
                    Learn About the Doctor
                </PrimaryBtn>
            </Box>
            <ImageArticle>
                <StaticImage
                    src="../../../assets/images/dr-luay.jpg"
                    alt="dr luay simhairy"
                    width={500}
                    height={500}
                />
                <div>
                    <h2
                        css={css`
                            max-width: 540px;
                        `}
                    >
                        Dr. Luay Simhairy, DDS
                    </h2>
                    <p>
                        Dr. Luay Simhairy has a DDS(Doctor of Dental Surgery) from USC, member of
                        OKU (Omicron Kappa Upsilon) and member of AAFE ( American Academy of Facial
                        Esthetic).  He had experience more than 20 years in dental field. He works
                        in different offices providing dental health to El Cajon and Rancho San
                        Diego community. 
                    </p>
                    <ArrowBtn as={CLink} to={getSubNavLink('our team')}>
                        Learn More About the Team
                    </ArrowBtn>
                </div>
            </ImageArticle>
        </StyledDr>
    );
};
